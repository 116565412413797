import React, { useMemo } from 'react';
// libs
import { useTranslation } from 'react-i18next';
// material-ui
import { Button, SelectChangeEvent } from '@mui/material';
// hooks
import usePaginationRange from 'hooks/usePaginationRange';
// icons
import { ReactComponent as ArrowLeftIcon } from 'assets/icons/arrow-left.svg';
import { ReactComponent as ArrowRightIcon } from 'assets/icons/arrow-right.svg';
// styles
import {
  GoToPageText,
  GoToPageWrapper,
  PaginationButton,
  PaginationCounts,
  PaginationDots,
  PaginationWrapper,
  StyledSelect,
} from './styled';
// types
import { TablePaginationProps } from './types';

const TablePagination: React.FC<TablePaginationProps> = ({ table }) => {
  const { t } = useTranslation('common');

  const totalPages = table.getPageCount();

  const paginationRange = usePaginationRange({
    currentPage: table.getState().pagination.pageIndex + 1,
    pages: totalPages,
  });

  const handleChange = (event: SelectChangeEvent<string | number | string[] | number[]>) => {
    table.setPageIndex(Number(event.target.value) - 1);
  };

  const goToPageOptions = useMemo(
    () =>
      totalPages !== -1
        ? Array(totalPages)
            .fill(null)
            .map((_, index) => ({ value: index + 1, label: index + 1 }))
        : [],
    [totalPages],
  );

  if (!paginationRange || (paginationRange && paginationRange.length < 2)) {
    return null;
  }

  return (
    <PaginationWrapper>
      <Button onClick={() => table.previousPage()} disabled={!table.getCanPreviousPage()} disableRipple>
        <ArrowLeftIcon /> {t('Anterior')}
      </Button>

      <PaginationCounts>
        {paginationRange.map((pageNumber, index) => {
          if (pageNumber === 'DOTS') {
            return <PaginationDots key={pageNumber + index}>...</PaginationDots>;
          }

          return (
            <PaginationButton
              key={pageNumber}
              onClick={() => table.setPageIndex(Number(pageNumber) - 1)}
              isActive={pageNumber === table.getState().pagination.pageIndex + 1}
              disableRipple
            >
              {pageNumber}
            </PaginationButton>
          );
        })}
      </PaginationCounts>

      <Button onClick={() => table.nextPage()} disabled={!table.getCanNextPage()} disableRipple>
        {t('Siguiente')} <ArrowRightIcon />
      </Button>

      <GoToPageWrapper>
        <GoToPageText variant='body2'>{t('Ir a la página')}</GoToPageText>

        <StyledSelect
          onChange={handleChange}
          options={goToPageOptions}
          value={table.getState().pagination.pageIndex + 1}
        />
      </GoToPageWrapper>
    </PaginationWrapper>
  );
};

export default TablePagination;
