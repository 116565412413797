import React, { memo, useCallback, useState } from 'react';
// libs
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
// material-ui
import { Chip, Stack, Theme, Typography, useMediaQuery } from '@mui/material';
// hooks
import useDocumentTitle from 'hooks/useDocumentTitle';
// components
import { DialogPopUp } from 'components';
// icons
import { ReactComponent as ArrowBackIcon } from 'assets/icons/arrow-back.svg';
// styles
import { BackButton } from './styled';
import { gray } from 'themes/colors';
// types
import { PageHeaderProps } from './types';

const PageHeader: React.FC<PageHeaderProps> = ({
  title,
  renderTitle,
  id,
  name,
  onClick,
  rightActions,
  enableConfirmBack = false,
}) => {
  useDocumentTitle(title);

  const matches = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));

  const navigate = useNavigate();
  const { t } = useTranslation('common');
  const [isConfirmModalOpen, setConfirmModalOpen] = useState(false);

  const toggleConfirmModal = useCallback(() => {
    setConfirmModalOpen(!isConfirmModalOpen);
  }, [isConfirmModalOpen]);

  const handleConfirmModal = useCallback(() => {
    if (onClick) {
      onClick();
    } else {
      navigate(-1);
    }
  }, [navigate, onClick]);

  const handleBack = useCallback(() => {
    if (enableConfirmBack) {
      toggleConfirmModal();
    } else {
      if (onClick) {
        onClick();
      } else {
        navigate(-1);
      }
    }
  }, [enableConfirmBack, navigate, onClick, toggleConfirmModal]);

  return (
    <Stack
      flexDirection={matches ? 'row' : 'column'}
      justifyContent='space-between'
      alignItems={matches ? 'center' : 'flex-start'}
      mb={2}
      gap={1}
    >
      {renderTitle ? renderTitle() : <Typography variant='h5'>{title}</Typography>}

      <Stack flexDirection={matches ? 'row' : 'column'} alignItems='center' gap={2}>
        {id && <Chip label={<Typography variant='subtitle2'>{id}</Typography>} sx={{ background: gray[100] }} />}

        {name && (
          <Typography variant='h6' fontSize='16px'>
            {name}
          </Typography>
        )}

        {rightActions ? (
          <Stack direction={matches ? 'row' : 'column'} alignItems={matches ? 'center' : 'flex-start'} gap={1}>
            {rightActions}
          </Stack>
        ) : (
          <BackButton variant='outlined' onClick={handleBack} disableRipple className='hideOnPrint'>
            <ArrowBackIcon /> {t('Atrás')}
          </BackButton>
        )}
      </Stack>

      <DialogPopUp
        title={t('Confirmar Regreso')}
        isOpen={isConfirmModalOpen}
        cancelButtonLabel={t('No')}
        successButtonLabel={t('Sí')}
        onCancel={toggleConfirmModal}
        onConfirm={handleConfirmModal}
      >
        {t('¿Estás seguro?')}
      </DialogPopUp>
    </Stack>
  );
};

export default memo(PageHeader);
