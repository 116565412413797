import React from 'react';
// libs
import { useTranslation } from 'react-i18next';
// material-ui
import { Button, DialogActions, DialogContent, DialogTitle } from '@mui/material';
// components
import { Checkbox } from 'components';
// styles
import { ColumnsWrapper, StyledDialog } from './styled';
// types
import { TablePickColumnsModalProps } from './types';

const disabledColumnsIds = ['id', 'status', 'name'];

const TablePickColumnsModal: React.FC<TablePickColumnsModalProps> = ({ table, isOpen, handleClose, onSubmit }) => {
  const { t } = useTranslation(['table', 'common']);

  return (
    <StyledDialog open={isOpen} onClose={handleClose}>
      <DialogTitle>{t('Seleccionar columnas')}</DialogTitle>

      <DialogContent>
        <ColumnsWrapper>
          {table
            .getAllLeafColumns()
            .filter((column) => !['select', 'actions'].includes(column.id))
            .map((column) => (
              <Checkbox
                key={column.id}
                checked={column.getIsVisible()}
                label={t(`${column.columnDef.meta?.title}`)}
                onChange={column.getToggleVisibilityHandler()}
                disabled={disabledColumnsIds.includes(column.id)}
              />
            ))}
        </ColumnsWrapper>
      </DialogContent>

      <DialogActions>
        <Button variant='outlined' disableRipple onClick={handleClose}>
          {t('Cancelar', { ns: 'common' })}
        </Button>

        <Button
          variant='contained'
          disableRipple
          onClick={() => {
            handleClose?.();
            onSubmit?.(
              table.getAllLeafColumns().reduce((acc, column) => ({ ...acc, [column.id]: column.getIsVisible() }), {}),
            );
          }}
        >
          {t('Aceptar', { ns: 'common' })}
        </Button>
      </DialogActions>
    </StyledDialog>
  );
};

export default TablePickColumnsModal;
