import type {} from '@mui/lab/themeAugmentation';
import { blue } from '@mui/material/colors';
import { createTheme } from '@mui/material/styles';
import { error, gray, orange, primary, secondary, success } from './colors';
import { shadows } from './shadows';

import './fonts.css';

const theme = createTheme({
  typography: {
    fontFamily: 'Gilroy',

    h4: {
      fontSize: 36,
      fontWeight: 600,
      color: gray[900],
      lineHeight: '44px',
      letterSpacing: '-0.02em',
    },
    h5: {
      fontSize: 30,
      fontWeight: 600,
      color: gray[900],
      lineHeight: '38px',
    },
    h6: {
      color: gray[900],
      lineHeight: '30px',
    },
  },
  palette: {
    primary: {
      main: primary[600],
      light: primary[100],
      dark: primary[900],
      contrastText: primary[300],
    },
    error: {
      main: error[500],
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          fontSize: 16,
          lineHeight: '24px',
          borderRadius: 8,
          height: 44,
          boxShadow: shadows.xs,
          textTransform: 'none',
          minWidth: 'auto',
          padding: '10px 18px',

          '& > svg': {
            transition: 'fill 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, stroke 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
          },
        },
        containedPrimary: {
          gap: 8,
          backgroundColor: primary[600],
          color: '#fff',

          '&:hover': {
            color: primary[600],
            border: `1px solid ${primary[600]}`,
            boxShadow: 'none',
            backgroundColor: 'transparent',
          },

          '&.Mui-disabled': {
            backgroundColor: primary[200],
            color: '#fff',
          },
        },
        containedSecondary: {
          gap: 8,
          backgroundColor: secondary[500],
          color: '#fff',
          '&:hover': {
            boxShadow: 'none',
            backgroundColor: secondary[500],
          },
        },
        outlinedPrimary: {
          gap: 12,
          color: gray[700],
          borderColor: gray[300],
          backgroundColor: '#fff',

          '&:hover': {
            borderColor: gray[300],
            backgroundColor: gray[50],
          },
        },
        outlinedSecondary: {
          gap: 8,
          color: '#fff',
          borderColor: gray[600],
          backgroundColor: 'transparent',

          '&:hover': {
            color: gray[600],
            borderColor: '#94A0BD',
            backgroundColor: '#94A0BD',

            '& > svg': {
              fill: gray[600],
            },
          },
        },
        textPrimary: {
          boxShadow: 'none',
          gap: 8,
          color: gray[500],
          fontSize: 14,
          lineHeight: '20px',
          padding: '0 8px',

          '&:hover': {
            color: '#fff',
            backgroundColor: primary[600],

            '& > svg': {
              stroke: '#fff',
            },
          },

          '&.Mui-disabled': {
            color: gray[300],

            '& > svg': {
              stroke: gray[300],
            },
          },
        },
      },
    },
    MuiLoadingButton: {
      styleOverrides: {
        loadingIndicator: {
          color: '#fff',
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: 8,
          backgroundColor: '#fff',
          boxShadow: shadows.xs,
          lineHeight: '24px',
          fontWeight: 500,
          color: gray[900],

          '&:hover': {
            '& .MuiOutlinedInput-notchedOutline': {
              borderColor: gray[300],
            },
          },

          '&.Mui-focused': {
            boxShadow: '0px 0px 0px 4px #CFD5E5, 0px 1px 2px 0px rgba(16, 24, 40, 0.05)',

            '& .MuiOutlinedInput-notchedOutline': {
              borderWidth: 1,
              borderColor: gray[300],
            },
          },

          '&.Mui-disabled': {
            color: gray[500],
            backgroundColor: gray[50],

            '& .MuiOutlinedInput-notchedOutline': {
              borderColor: gray[300],
            },
          },

          '&.Mui-error': {
            '&.Mui-focused': {
              boxShadow: '0px 1px 2px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px #FEE4E2',
            },

            '& .MuiOutlinedInput-notchedOutline': {
              borderColor: error[300],
            },
          },

          '&.MuiInputBase-multiline': {
            padding: '0 16px 0 0',
          },
        },
        input: {
          height: 'auto',
          padding: '10px 14px',

          '&::placeholder': {
            opacity: 1,
            color: gray[500],
          },

          '&.Mui-disabled': {
            WebkitTextFillColor: gray[500],
          },
        },
        notchedOutline: {
          borderColor: gray[300],
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          fontSize: 14,
          fontWeight: 500,
          lineHeight: '20px',
          color: gray[700],
          marginBottom: 6,
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          fontSize: 14,
          lineHeight: '20px',
          color: gray[500],
          margin: '6px 0 0',
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        popper: {
          '&&[data-popper-placement*="right"] > .MuiTooltip-tooltip': {
            marginLeft: 26,
          },
        },
        tooltip: {
          fontSize: 12,
          borderRadius: 8,
          lineHeight: '18px',
          padding: '8px 12px',
          boxShadow: shadows.lg,
          backgroundColor: gray[900],
        },
        arrow: {
          '&::before': {
            backgroundColor: gray[900],
          },
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          backgroundColor: gray[100],
          color: gray[700],
          height: 'fit-content',
        },
        colorSuccess: {
          backgroundColor: success[50],
          color: success[700],
        },
        colorWarning: {
          backgroundColor: orange[50],
          color: orange[700],
        },
        colorError: {
          backgroundColor: error[100],
          color: error[600],
        },
        colorInfo: {
          backgroundColor: blue[50],
          color: blue[700],
        },
        colorPrimary: {
          backgroundColor: orange[50],
          color: primary[600],
        },

        labelMedium: {
          padding: '2px 10px',
          fontSize: 14,
        },
        labelSmall: {
          padding: '2px 8px',
          fontSize: 12,
          lineHeight: '18px',
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          backgroundColor: gray[100],
          borderRadius: '6px',
          padding: '4px',
          '& div.MuiTabs-scroller': {
            '& .MuiTabs-flexContainer': {
              gap: '16px',
            },
          },
        },
        indicator: {
          display: 'none',
        },
        scrollButtons: {
          width: 25,
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          color: gray[500],
          padding: '12px',
          lineHeight: '150%',
          fontWeight: 500,
          position: 'relative',

          '&.Mui-selected': {
            color: '#fff',
            backgroundColor: primary[600],
            borderRadius: '6px',
          },
        },
      },
    },
    MuiMenu: {
      styleOverrides: {
        paper: {
          '&.MuiPaper-root': {
            borderRadius: 8,
            border: `1px solid ${gray[100]}`,
            boxShadow: shadows.lg,
          },
        },
      },
    },
    MuiList: {
      styleOverrides: {
        root: {
          padding: 0,
        },
      },
    },
    MuiInputAdornment: {
      styleOverrides: {
        root: {
          margin: 0,

          '& > p': {
            fontWeight: 500,
            color: gray[900],
          },
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        root: {
          '& .MuiInputBase-root': {
            padding: '10px 39px 10px 14px',

            '& .MuiChip-root': {
              height: '24px',
              border: `1px solid ${gray[300]}`,
              backgroundColor: 'transparent',
              borderRadius: '6px',

              '& > svg': {
                marginRight: '8px',
              },
            },

            '& .MuiAutocomplete-input': {
              padding: 0,
            },
          },
        },
        popper: {
          '& .MuiAutocomplete-paper': {
            borderRadius: '8px',
            boxShadow: shadows.lg,

            '& .MuiAutocomplete-listbox': {
              padding: 0,

              '& .MuiAutocomplete-option': {
                padding: '10px 14px',

                '&.Mui-focused': {
                  backgroundColor: gray[50],
                },
              },
            },
          },
        },
        tag: {
          margin: 0,
        },
        inputRoot: {
          gap: 6,
        },
      },
    },
    MuiPopover: {
      styleOverrides: {
        paper: {
          borderRadius: 8,
          border: `1px solid ${gray[100]}`,
          boxShadow: shadows.lg,
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          padding: 0,

          '& > svg': {
            transition: 'fill 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
          },

          '&:hover > svg': {
            fill: gray[700],
          },

          '&.MuiClock-amButton, &.MuiClock-pmButton': {
            width: 32,
            height: 32,
          },
        },
      },
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          backgroundColor: 'transparent',
          boxShadow: 'none',

          '&.MuiAccordion-root:before': {
            display: 'none',
          },
        },
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          padding: 0,
          borderBottom: `1px solid ${gray[200]}`,
        },
        expandIconWrapper: {
          '& > svg': {
            width: 24,
            height: 24,

            path: {
              stroke: gray[900],
            },
          },
        },
      },
    },
    MuiAccordionDetails: {
      styleOverrides: {
        root: {
          padding: '24px 16px',
        },
      },
    },
    MuiToggleButton: {
      styleOverrides: {
        root: {
          backgroundColor: primary[50],
          border: 'none',

          '&.Mui-selected': {
            backgroundColor: primary[600],
            color: '#fff',

            '&:hover': {
              backgroundColor: primary[600],
              color: '#fff',
            },
          },

          '&:hover': {
            backgroundColor: primary[100],
          },
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        root: {
          '& > .MuiBackdrop-root': {
            backgroundColor: 'rgba(52, 64, 84, 0.7)',
            backdropFilter: 'blur(8px)',
          },
        },
        paper: {
          borderRadius: 12,
          boxShadow: shadows.xl,
          margin: '70px 32px',
          maxHeight: 'calc(100% - 140px)',
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          fontWeight: 600,
          fontSize: 18,
          lineHeight: '28px',
          color: gray[900],
          padding: '24px 24px 10px',
        },
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          '&&': {
            padding: '10px 24px',
          },
        },
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          padding: '22px 24px 24px',

          '& > button': {
            width: '100%',
          },

          '& > :not(:first-of-type)': {
            marginLeft: 12,
          },
        },
      },
    },
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
    },
  },
});

export default theme;
